import { endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays, subHours, subMonths } from 'date-fns'
import { title } from 'radash'
import colors from 'tailwindcss/colors'

type ColorsKeyNumbers = '50' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'

export const colorsChoices = [
  'blue',
  'red',
  'yellow',
  'green',
  'purple',
  'pink',
  'indigo',
  'cyan',
  'teal',
  'lime',
  'primary',
  'emerald',
  'amber',
] as const

export function getRandomColor(tailwindColorNumber: ColorsKeyNumbers) {
  const twColorsChoices = [
    colors.blue[tailwindColorNumber],
    colors.red[tailwindColorNumber],
    colors.yellow[tailwindColorNumber],
    colors.green[tailwindColorNumber],
    colors.purple[tailwindColorNumber],
    colors.pink[tailwindColorNumber],
    colors.indigo[tailwindColorNumber],
    colors.cyan[tailwindColorNumber],
    colors.teal[tailwindColorNumber],
    colors.lime[tailwindColorNumber],
    colors.orange[tailwindColorNumber],
    colors.emerald[tailwindColorNumber],
    colors.amber[tailwindColorNumber],
  ]
  return twColorsChoices[Math.floor(Math.random() * twColorsChoices.length)]
}

export function getRandomColorClass<T extends ColorsKeyNumbers, U extends 'bg' | 'text' | 'border'>(tailwindColorNumber: T, classPrefix: U): `${U}-${typeof colorsChoices[number]}-${T}` {
  return `${classPrefix}-${colorsChoices[Math.floor(Math.random() * colorsChoices.length)]}-${tailwindColorNumber}`
}

export function takeRandomFromArray<T>(arr: T[], n: number = 1): T[] {
  const shuffled = arr.sort(() => 0.5 - Math.random())
  return shuffled.slice(0, n)
}

export async function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function asDisplayableName(name: string) {
  return title(name)
}

export function getInitials(name: string) {
  return name
    .split(' ')
    .map(word => word[0].toUpperCase())
    .splice(0, 2)
    .join('')
}

type HexString = `${string}${string}${string}`
function textColor(bgColor: HexString | 'transparent', { lightColor = 'white', darkColor = 'black' } = {}) {
  if (bgColor === 'transparent') { return 'black' }
  const bgColorToHex = bgColor.replace('#', '').match(/.{1,2}/g) as [HexString, HexString, HexString]

  const r = Number.parseInt(bgColorToHex[0], 10)
  const g = Number.parseInt(bgColorToHex[1], 10)
  const b = Number.parseInt(bgColorToHex[2], 10)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness > 125 ? darkColor : lightColor
}

export function twBgTextColorClassesFromName(name: string, tailwindColorNumber: ColorsKeyNumbers, { lightColor = 'white', darkColor = 'black' } = {}) {
  const colorIndex = name.length % colorsChoices.length
  const bgColor = colorsChoices[colorIndex]
  const bgColorForTw = colors[bgColor as Exclude<typeof colorsChoices[number], 'primary'>][tailwindColorNumber]
  const color = textColor(bgColorForTw, { lightColor, darkColor })

  return `bg-${bgColor}-${tailwindColorNumber} ${color}`
}
